import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LanguagesService } from './services/languages.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  /**
   * Are cookies accepted.
   *
   * @type {boolean}
   * @memberof AppComponent
   */
  cookiesAccepted: boolean = false;

  constructor(
    private languageService: LanguagesService,
    public dialog: MatDialog
  ) {

    // Autodiscover user language
    this.languageService.autodiscover();

  }
}
