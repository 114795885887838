import { Injectable, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { localeConfig } from '../configs/locale.config';
import { LangOption } from '../shared/interfaces/lang-option';

@Injectable({
  providedIn: 'root'
})
export class LanguagesService {

  /**
   * Current lang option.
   *
   * @type {LangOption}
   * @memberof LanguagesService
   */
  currentLangOption: LangOption;

  /**
   * Event for language change.
   *
   * @type {EventEmitter<number>}
   * @memberof LanguagesService
   */
  languageChanged: EventEmitter<Object> = new EventEmitter();

  /**
   * Creates an instance of LanguagesService.
   * 
   * @param {TranslateService} translate
   * @param {CookieService} cookieService
   * @memberof LanguagesService
   */
  constructor(
    private translate: TranslateService,
    private cookieService: CookieService
  ) {
    this.autodiscover();
   }

  /**
   * Discover the user language from cookie or browser.
   *
   * @memberof LangSwitcherComponent
   */
  autodiscover() {
    let localeCode = this.cookieService.get('_lng');
    
    // There is no cookie, get the lang from the browser.
    if (!localeCode) {
      localeCode = this.translate.getBrowserLang();
    }

    // Check if the lang code is available
    let langOption = localeConfig.available.find((item: LangOption) => item.code === localeCode);
    if (!langOption) {
      this.currentLangOption = localeConfig.available.find((item: LangOption) => localeConfig.default === localeCode)
    } else {
      this.currentLangOption = langOption;
    }

    this.setLangOption(this.currentLangOption);
  }

  /**
   * Get available languages.
   *
   * @returns {Array<LangOption>}
   * @memberof LanguagesService
   */
  getAvailableLanguages(): Array<LangOption> {
    return localeConfig.available;
  }

  /**
   * Set the language at application level.
   *
   * @param {string} code
   * @memberof LangSwitcherComponent
   */
  setLangOption(langOption: LangOption, reload: boolean = false): void {
    
    if(!langOption) {
      langOption = {
        title: 'Spanish',
        code: 'es'
      }
    }

    this.translate.setDefaultLang(langOption.code);
    this.translate.use(langOption.code);
    this.cookieService.set('_lng', langOption.code, 365, '/');
    this.currentLangOption = langOption;
    this.languageChanged.emit(langOption);
    
    if(reload) {
      window.location.reload();
    }
  }
}
